import Component from "@base/Component";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Modal} from 'antd';

import SwiperCore, {
    Virtual,
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination,
    Scrollbar,
    Parallax,
    Zoom,
    Lazy,
    Controller,
    A11y,
    History,
    HashNavigation,
    Autoplay,
    EffectFade,
    EffectCube,
    EffectFlip,
    EffectCoverflow,
    Thumbs
} from "swiper"

SwiperCore.use([Virtual, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar, Parallax, Zoom, Lazy, Controller, A11y, History, HashNavigation, Autoplay, EffectFade, EffectCube, EffectFlip, EffectCoverflow, Thumbs])


import {Row, Col, Button} from 'antd';
import 'swiper/swiper.min.css';
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import {HeartFilled, HeartOutlined} from '@ant-design/icons';


// https://swiperjs.com/swiper-api

class Carousel extends Component {

    data = {
        content: '',
    }

    modal = (id, data) => {
        const M = class X extends Component {

            data = {visible: true}

            render() {


                let data = this.props.d

                let id = this.props.id;

                if (!data)
                    return ''

                let mc = {
                    footer: false,
                    title: false,
                    visible:this.data.visible||this.data.open,
                    onOk: () => this.set({visible: false}),
                    onCancel: () => this.set({visible: false}),
                    closeIcon: <div className={'close-modal-x'}>×</div>,
                    className: 'main-carousel-modal',
                    destroyOnHide: true
                }

                let helpBt = (

                    <Button block className={'yellowBt'} onClick={() => {
                        this.set({visible: false})
                        this.to(this.app().data.menuLinks.wantToHelp)
                    }}>
                        {this.t('Помочь')}
                    </Button>

                )

                let gridConf = {
                    1: {xs: {span: 24}, sm: {span: 24}, md: {span: 24}, lg: {span: 6}, xl: {span: 5}},
                    2: {
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 24},
                        lg: {span: 12, offset: 1},
                        xl: {span: 14, offset: 0}
                    },
                    3: {xs: {span: 24}, sm: {span: 24}, md: {span: 24}, lg: {span: 5}, xl: {span: 5}},
                }

                return (
                    <div>
                        <Modal {...mc} >

                            <Row align={'center'} justify={'middle'}>
                                <Col {...gridConf[1]}>
                                    <div className={'round-img'} style={{background: "url(" + data.img + ")"}}></div>
                                </Col>
                                <Col {...gridConf[2]} className={'info'}>
                                    <h1>
                                        {data.name}
                                    </h1>
                                    <p className="font-slim">
                                        {data.date} - {data.city}
                                    </p>
                                    <span className="font-slim">
                                    {this.t('Диагноз:')} &nbsp;
                                </span>

                                    <b>{data.diagnosis}</b>

                                </Col>
                                <Col {...gridConf[3]} className={'info tac'}>
                                    <div>
                                        {this.t('Стоимость')}
                                    </div>
                                    <h2>
                                        {this.numberFormat(data.price)} {this.getRubSign()}
                                    </h2>
                                    <Row>
                                        <Col xs={{span: 14, offset: 5}} lg={{span: 24, offset: 0}}>
                                            {helpBt}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br/>
                            <div className="modal-separator"></div>
                            <br/>
                            <div className="font-slim fs-md">
                                {this.renderHtml(data.story)}
                            </div>
                            <br/>
                            <br/>

                            <Row>
                                <Col xs={{span: 14, offset: 5}} lg={{span: 14, offset: 5}}>
                                    {helpBt}
                                </Col>
                            </Row>
                        </Modal>
                    </div>
                )
            }
        }

        this.app().set({
            modal: <M id={id} d={data}/>
        })
    }


    renderItem = (nr, d) => {
        let data = d[1];

        if (data.open){
            setTimeout(()=>{
                this.modal(nr, data)
            },200)
        }

        if (!data || !data.name)
            return '';

        return (
            <SwiperSlide key={nr}>
                <div className={'carousel-item'}>
                    <Row align={'middle'} justify={'center'} onClick={() => this.modal(nr, data)}>
                        <Col xs={{span: 14}} sm={{span: 8}}>
                            <div className={'round-img'} style={{background: "url(" + data.img + ")"}}>
                            </div>
                        </Col>

                        <Col span={16}>
                            <h2>
                                {data.name}
                            </h2>

                            <p>
                                {data.date} - {data.city}
                            </p>

                            {this.t('Диагноз: {diagnosis}', {
                                diagnosis: data.diagnosis
                            })}
                            <br/>
                            <br/>
                        </Col>
                    </Row>

                    <Row align={'middle'} justify={'right'} className={'carousel-item-footer'}>

                        <Col xs={{span: 22, offset: 1}} md={{span: 22, offset: 1}} lg={{span: 8, offset: 0}}
                             xl={{span: 8}} onClick={() => this.modal(nr, data)}>
                            <div className={'info'}>
                                <Row>
                                    <Col xs={{span: 12}} sm={{span: 24}} md={{span: 12}} lg={{span: 24}}>
                                        <p className={'fontSlim'}>
                                            {this.t('Стоимость')}
                                        </p>
                                    </Col>
                                    <Col xs={{span: 12}} sm={{span: 24}} md={{span: 12}} lg={{span: 24}}>
                                        <b>
                                            {this.numberFormat(data.price)} {this.getRubSign()}
                                        </b>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={{span: 22, offset: 1}} md={{span: 11, offset: 1}} lg={{span: 8, offset: 0}}
                             xl={{span: 7}}>
                            <Button block type="ghost" shape="round" size={'large'}
                                    onClick={() => {
                                        // return this.to(`/${data.name}`)
                                        window.location.href = `/${data.name}`
                                    }}>

                                <div className={'carousel-ghost-btn-c'}>
                                    <HeartFilled style={{display: 'none'}} className={'f'}/>
                                    <HeartOutlined className={'o'}/>
                                    {this.t('Моя история')}
                                </div>
                            </Button>
                        </Col>
                        <Col xs={{span: 22, offset: 1}} md={{span: 10, offset: 1}} lg={{span: 6, offset: 1}}
                             xl={{span: 7, offset: 1}}>
                            <Button onClick={() => this.to('/want-to-help')} block type="primary" shape="round"
                                    size={'large'}>
                                {this.t('Помочь')}
                            </Button>
                        </Col>
                    </Row>
                    <br/>
                </div>
            </SwiperSlide>
        )
    }

    onMount = () => {
        let items = this.app().getBulkData('carousel', false);
        if (items) {
            let data = {}
            items.map((entry, i) => {
                let item = {
                    img: entry.image[0] ? entry.image[0].url : '',
                    name: entry.name,
                    date: entry.birth_date,
                    city: entry.region,
                    diagnosis: entry.diagnosis,
                    price: entry.cost,
                    story: entry.history,
                    open:decodeURIComponent(window.location.pathname) === `/${entry.name}`
                };
                data[i + 1] = item
            })
            return this.initCarousel(data);
        }
    }

    render() {
        return (
            <div className={'home-page-carousel custom-swiper-container'}>
                {this.data.content}
            </div>
        )
    }

    initCarousel = (d) => {

        let conf = {
            effect: 'slide', //'slide' | 'fade' | 'cube' | 'coverflow' | 'flip',
            loop: false,
            spaceBetween: 50,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            slidesPerView: 1,
            breakpoints: {
                992: {
                    slidesPerView: 2,
                }
            },
            //onSlideChange: () => console.log('slide change'),
            //onSwiper: (swiper) => console.log(swiper),
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            scrollbar: false //{draggable: true}
        }

        let items = [];

        Object.entries(d).map((value, key) => {
            items.push(this.renderItem(key, value))
        })

        this.set({
            content: (
                <Swiper {...conf}>
                    {items}
                    <div className={'navigation-container'}>
                        <div className={'navigation-block'}>
                            <div className={'swiper-pagination'}></div>
                            <div className={'swiper-button-prev'}></div>
                            <div className={'swiper-button-next'}></div>
                        </div>
                    </div>
                </Swiper>
            )
        })

    }
}

export default Carousel;